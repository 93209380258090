@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.progress-bar-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

#progress-bar {
    width: 30%;
    height: 2%;
    margin-top: 0.5%;
}

label {
    color: white;
    font-size: 2rem;
}

.header {
    position: absolute;
    left: 50%;
    top: 10%;
    transform: translateX(-50%);
    text-align: center;
    z-index: 100;
}

.header h1 {
    font-family: 'Montserrat Extrabold';
    font-size: 3.5rem;
}

.header button {
    font-size: 1.6rem;
    background-color: black;
    color: white;
    padding: 0.3rem;
    cursor: pointer;
}

.header button:hover {
    background-color: white;
    color: black;
}

.questions {
    position: absolute;
    top: 1%;
    left: 2%;
}

.questions p, ul {
    color: white;
    font-size: 1.8rem;
}

.questions p {
    padding: 0.5rem;
    background-color: black;
    opacity: 0;
}

.questions, ul {
    list-style: none;
    padding: 0;
    margin-top: 0;
    display: inline-block;
}

.questions ul li {
    cursor: pointer;
    padding: 0.5rem;
    background-color: black;
    transform: rotateX(90deg);
}

.questions ul li span:nth-child(2) {
    background-size: cover;
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-bottom: -5px;
    margin-right: 0.3rem;
}

.explanation {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1%;
    width: 90%;
    text-align: right;
    opacity: 0;
}

.explanation button {
    font-size: 1.4rem;
}

.explanation .text-wrapper p {
    font-size: 1.6rem;
    background-color: black;
    color: white;
    padding: 0.8rem;
    text-align: left;
}

.score {
    position: absolute;
    right: 2%;
    font-size: 1.5rem;
    background-color: black;
    color: white;
    padding: 0.5rem;
}