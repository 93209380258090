@import "https://fonts.googleapis.com/css2?family=Montserrat&display=swap";
body {
  margin: 0;
  font-family: Montserrat, sans-serif;
}

.progress-bar-container {
  z-index: 1000;
  background-color: #000000e6;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#progress-bar {
  width: 30%;
  height: 2%;
  margin-top: .5%;
}

label {
  color: #fff;
  font-size: 2rem;
}

.header {
  text-align: center;
  z-index: 100;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.header h1 {
  font-family: Montserrat Extrabold;
  font-size: 3.5rem;
}

.header button {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  padding: .3rem;
  font-size: 1.6rem;
}

.header button:hover {
  color: #000;
  background-color: #fff;
}

.questions {
  position: absolute;
  top: 1%;
  left: 2%;
}

.questions p, ul {
  color: #fff;
  font-size: 1.8rem;
}

.questions p {
  opacity: 0;
  background-color: #000;
  padding: .5rem;
}

.questions, ul {
  margin-top: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.questions ul li {
  cursor: pointer;
  background-color: #000;
  padding: .5rem;
  transform: rotateX(90deg);
}

.questions ul li span:nth-child(2) {
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-bottom: -5px;
  margin-right: .3rem;
  display: inline-block;
}

.explanation {
  text-align: right;
  opacity: 0;
  width: 90%;
  position: absolute;
  bottom: 1%;
  left: 50%;
  transform: translateX(-50%);
}

.explanation button {
  font-size: 1.4rem;
}

.explanation .text-wrapper p {
  color: #fff;
  text-align: left;
  background-color: #000;
  padding: .8rem;
  font-size: 1.6rem;
}

.score {
  color: #fff;
  background-color: #000;
  padding: .5rem;
  font-size: 1.5rem;
  position: absolute;
  right: 2%;
}
/*# sourceMappingURL=index.dfa6eb59.css.map */
